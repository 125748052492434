import React from "react";
import HeaderSpacer from "../../../components/HeaderSpacer";
import Square from "../../../components/Square";
import SpringDowntownCol from "../../../data/shop/SpringDowntown_Data";
import BackButton from "../../../components/BackButton";
import SquareSpacer from "../../../components/SquareSpacer";
import SlideShow from "../../../components/SlideShow";
import { Helmet } from "react-helmet-async";

function SpringDowntownNYC() {
  return (
    <div>
      <Helmet>
        <title>TL | Spring Downtown NYC</title>
        <meta property='og.title' content='Spring Downtown NYC- 2025' />
        <meta property='og.image' content={SpringDowntownCol[0].image} />
      </Helmet>
      <HeaderSpacer />

      <p>
        <strong>Spring Downtown NYC- 2025.</strong>
      </p>
      <p>
        <strong>Limited Edition of 12 prints +1 A.P</strong>
      </p>
      <p>
        Giclee archival pigment print on 310 gsm 100% cotton rag fine art
        archival paper.
      </p>
      <p>Print Size 74cm x 74cm / 29.13in x 29.13in.</p>
      <p>Paper Size 82cm x 82cm / 32.28in x 32.28in.</p>
      <p>
        Signed and Numbered on the front bottom right of paper, and on the
        reverse side of paper.
      </p>
      <p>
        <strong>$270 AUD each</strong> - Unframed.
      </p>
      <p>Includes FREE postage within Australia only.</p>
      <p>$20 shipping to NZ & $30 anywhere else in the world.</p>
      <p>Item is shipped rolled up in a tube via Australia Post.</p>
      <p>Allow 4-7 working days before shipment is dispatched.</p>
      <p>
      </p>
      <SlideShow
        slides={SpringDowntownCol}
        slideShowName='SpringDowntownNYCSlideshow'
        startingIndex={0}
        scrollOffset={-45}
        shopSlideShow={true}
      />
      <Square
        thePrice='$270.00 - $300.00'
        theTitle='Spring Downtown NYC'
        theLink='https://square.link/u/Km3EliG4?src=embed'
      />
      <SquareSpacer />
      <BackButton />
    </div>
  );
}

export default SpringDowntownNYC;
