import i1 from "../../images/shop/28thprintFiDiSpring/1_fIDISpring.jpg";
import i2 from "../../images/shop/28thprintFiDiSpring/2_fIDISpring.jpg";
import i3 from "../../images/shop/28thprintFiDiSpring/3_fIDISpring.jpg";
import i4 from "../../images/shop/28thprintFiDiSpring/4_fIDISpring.jpg";
import i5 from "../../images/shop/28thprintFiDiSpring/5_fIDISpring.jpg";
import i6 from "../../images/shop/28thprintFiDiSpring/6_fIDISpring.jpg";
import i7 from "../../images/shop/28thprintFiDiSpring/7_fIDISpring.jpg";

const SpringDowntownCol = [
  { image: i1, alt: "Spring Downtown NYC" },
  { image: i2, alt: "Spring Downtown NYC" },
  { image: i3, alt: "Spring Downtown NYC" },
  { image: i4, alt: "Spring Downtown NYC" },
  { image: i5, alt: "Spring Downtown NYC" },
  { image: i6, alt: "Spring Downtown NYC" },
  { image: i7, alt: "Spring Downtown NYC" },
 ];

export default SpringDowntownCol;
