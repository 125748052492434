import React from "react";
import Home from "../pages/Home";
import { Routes, Route } from "react-router-dom";
// import { Route } from "react-router-dom";

import Biography from "../pages/Biography";

import Paintings from "../pages/Paintings";
import Shop from "../pages/Shop";
import PrintShop from "../pages/PrintShop";
import PaintingShop from "../pages/PaintingShop";
import Abstracts from "../pages/paintings/Abstracts";
import Cityscapes from "../pages/paintings/Cityscapes";
import Misc from "../pages/paintings/Misc";
import PeoplescapesOld from "../pages/paintings/PeoplescapesOld";
import PeoplescapesNew from "../pages/paintings/PeoplescapesNew";
import ParisOriginal from "../pages/shop/Paintings/ParisOriginal";
import Betoota from "../pages/shop/Paintings/Betoota";
import Optimist2original from "../pages/shop/Paintings/Optimist2Original";
import EuroSummerOriginal from "../pages/shop/Paintings/EuroSummerOriginal";
import Hollywood from "../pages/shop/Prints/Hollywood";
import MiniCapsuleOriginal from "../pages/shop/Paintings/miniCapsuleOriginal";
import Interiors from "../pages/paintings/Interiors";
import Thredbo4 from './../pages/shop/Prints/Thredbo4';
import TheOptimist2 from "../pages/shop/Prints/TheOptimist2";
import Paris from "../pages/shop/Prints/Paris";
import Tokyo from "../pages/shop/Prints/Tokyo";
import EuroSummer from "../pages/shop/Prints/EuroSummer";
import SpringDowntownNYC from "../pages/shop/Prints/SpringDowntownNYC";

const TheRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/biography' element={<Biography />} />
      <Route path='/shop' element={<Shop />} />
      <Route path='/painting-shop/ParisOriginal' element={<ParisOriginal />} />
      <Route path='/painting-shop/EuroSummerOriginal' element={<EuroSummerOriginal />} />
      <Route path='/painting-shop/betoota' element={<Betoota />} />
      <Route path='/painting-shop/Optimist2Original' element={<Optimist2original />} />
      <Route path='/painting-shop/miniCapsuleOriginal' element={<MiniCapsuleOriginal />} />
      <Route path='/painting-shop' element={<PaintingShop />} />

      <Route path='/paintings' element={<Paintings />} />
      <Route path='/paintings/abstracts' element={<Abstracts />} />
      <Route path='/paintings/cityscapes' element={<Cityscapes />} />
      <Route path='/paintings/interiors' element={<Interiors />} />
      <Route path='/paintings/misc' element={<Misc />} />
      <Route path='/paintings/peoplescapes-new' element={<PeoplescapesNew />} />
      <Route path='/paintings/peoplescapes-old' element={<PeoplescapesOld />} />

      <Route path='/prints' element={<PrintShop />} />
      <Route path='/prints/hollywood' element={<Hollywood />} />
      <Route path='/prints/thredbo' element={<Thredbo4 />} />
      <Route path='/prints/theoptimist2' element={<TheOptimist2 />} />
      <Route path='/prints/paris' element={<Paris />} />
      <Route path='/prints/tokyo' element={<Tokyo />} />
      <Route path='/prints/eurosummer' element={<EuroSummer />} />
      <Route path='/prints/springdowntownnyc' element={<SpringDowntownNYC />} />
      
      <Route path='*' element={<Home />} />
    </Routes>
  );
};

export default TheRoutes;
